<template>
  <div class="flex-grow flex flex-col justify-between steps">
    <div v-if="account" >
      <div class="steps__title--mobile ml-5 md:ml-0">
        {{ $tm('withdraw.choose_bank_accounts.title') }}
      </div>
      <div class="steps__subtitle">
        {{ $tm('withdraw.choose_bank_accounts.subtitle') }}
      </div>
      <BankAccountElement
        :account="account"
        @next="next(account)"
        isWithdraw
      />
    </div>

    <GpButton
      mobileWidth="w-full"
      @click="addAccount"
      class="mt-4 lg:ml-auto"
      color="white"
    >
      {{
        $t(
          account
            ? 'topup.confirmation.bankwire.connect_other_bank'
            : 'topup.confirmation.bankwire.connect_bank'
        )
      }}
    </GpButton>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import GpButton from '@/components/general/GpButton.vue'
import BankAccountElement from '@/components/settings/BankAccountElement.vue'

export default {
  components: {
    BankAccountElement,
    GpButton
  },
  emits: ['next'],
  setup(props, { emit }) {
    const form = ref(null)
    const store = useStore()
    const router = useRouter()

    onMounted(async () => {
      await store.dispatch('setLoading', true)
      await store.dispatch('getBankAccount')
      await store.dispatch('setLoading', false)
    })

    const account = computed(() => {
      return store.state.bankAccounts.account
    })

    // Set bankaccount
    const next = async (value = null) => {
      // save as default if not already set
      await store.dispatch('setBankAccount', value)
      emit('next')
    }

    const addAccount = () => {
      router.push('/settings/bank-accounts')
    }

    return {
      form,
      account,
      addAccount,
      next
    }
  }
}
</script>