import axios from 'axios'
import { useStore } from 'vuex'
import useGlobalHelpers from '@/mixins/useGlobalHelpers.js'
export default {
  state: {
    projects: {
      projects : {
        items: [],
        has_next_page: true,
        next_page: 1,
        fetched: []
      },
      offers: {
        items: [],
        has_next_page: true,
        next_page: 1,
        fetched: []
      },
      campaigns: {
        items: [],
        has_next_page: true,
        next_page: 1,
        fetched: []
      }
    },
    promoter_projects: [],
    order: null,
    fetching: false,
    isCampaign: false,
    type: 'projects',
    project: null,
    fetchedProjects: []
  },
  mutations: {
    SET_PROJECTS(state, data) {
      state.projects[data.type].items = state.projects[data.type].items.concat(data.projects)
    },
    SET_IS_CAMPAIGN(state, isCampaign) {
      state.isCampaign = isCampaign
    },
    SET_FETCHING(state, fetching) {
      state.fetching = fetching
    },
    SET_PROMOTER_PROJECTS(state, promoter_projects) {
      state.promoter_projects =
        state.promoter_projects.concat(promoter_projects)
    },
    SET_PROJECT(state, project) {
      state.project = project
    },
    SET_TYPE(state, type) {
      state.type = type
    },
    CLEAR_PROJECTS(state) {
      state.projects[state.type].items = []
    },
    CLEAR_FETCHED(state) {
      state.projects[state.type].fetched = []
    },
    SET_FETCHED(state, page) {
      state.projects[state.type].fetched.push(page)
    },
    SET_NEXT_PAGE(state, next_page) {
      state.projects[state.type].next_page = next_page
    },
    SET_HAS_NEXT_PAGE(state, has_next_page) {
      state.projects[state.type].has_next_page = has_next_page
    },
    SET_ORDER(state, order) {
      state.order = order
    },
    SET_FETCHED_PROJECTS(state, newProject) {
      const existingProjectIndex = state.fetchedProjects.findIndex(item => item.id === newProject.id);
      if (existingProjectIndex !== -1) {
        state.fetchedProjects.splice(existingProjectIndex, 1, newProject);
      } else {
        state.fetchedProjects.push(newProject);
      }

      const existingProjectArrayIndex = state.projects[state.type].items.findIndex(item => item.id === newProject.id);
      if (existingProjectArrayIndex !== -1) {
        state.projects[state.type].items.splice(existingProjectArrayIndex, 1, newProject);
      }
    }
  },
  actions: {
    getPromoterProjects({ commit, state }, promoter_id) {
      return axios
        .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/' + state.type, {
          params: {
            'filter[promoter_id]': promoter_id
          }
        })
        .then((projects) => {
          commit('SET_PROMOTER_PROJECTS', projects.data.data)
          return projects.data.data
        })
    },
    getProjects({ commit, state }) {
      // in case the type changes mid request
      const type = state.type;
      if (!state.projects[type].fetched.includes(state.projects[type].next_page)) {
        commit('SET_FETCHING', true)
        commit('SET_FETCHED', state.projects[type].next_page)
        return axios
          .get(process.env.VUE_APP_SERVER_BASE_URL + 'api/v1/' + type, {
            params: {
              page: state.projects[type].next_page,
              sort: state.order ?? 'created_at',
              'filter[state]': state.isCampaign
                ? ''
                : 'campaign,comingsoon,reserved'
            }
          })
          .then((projects) => {
            commit('SET_PROJECTS', {projects: projects.data.data, type: type})
            commit('SET_HAS_NEXT_PAGE', projects.data.next_page_url)
            commit('SET_NEXT_PAGE', state.projects[type].next_page + 1)
            commit('SET_FETCHING', false)
            return projects.data.data
          })
      }
    },
    getCampaign({ commit }, id) {
      const { isInt } = useGlobalHelpers()
      const store = useStore()
      if (isInt(id))
        return axios
          .get(
            process.env.VUE_APP_SERVER_BASE_URL +
              'api/v1/campaigns/' +
              parseInt(id)
          )
          .then((data) => {
            commit('SET_PROJECT', data.data)
          }).catch((e)=>{
            if(e.response){
              if(e.response.status === 404)
                window.location.href = '/404'
            }
          })
      else
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'invalid_project'
            }
          }
        })
    },
    getProject({ commit, state }, id) {
      const { isInt } = useGlobalHelpers()
      const store = useStore()
      if (isInt(id)) {
        const intId = parseInt(id)
        let project = null

        if(state.fetchedProjects) {
          project = state.fetchedProjects.find(item => item.id === intId);
          commit('SET_PROJECT', project)
        }

        return axios
            .get(
                process.env.VUE_APP_SERVER_BASE_URL +
                'api/v1/projects/' +
                intId
            )
            .then((data) => {
              commit('SET_PROJECT', data.data)
              commit('SET_FETCHED_PROJECTS', data.data)
            }).catch((e) => {
              if (e.response) {
                if (e.response.status === 404)
                  window.location.href = '/404'
              }
            })
      }
      else
        store.dispatch('setError', {
          response: {
            custom: true,
            status: 500,
            data: {
              code: 'invalid_project'
            }
          }
        })
    },
    resetProjects({ commit, dispatch }) {
      commit('CLEAR_PROJECTS')
      commit('SET_HAS_NEXT_PAGE', true)
      commit('SET_NEXT_PAGE', 1)
      commit('CLEAR_FETCHED')
      dispatch('getProjects')
    },
    setIsCampaign({ commit }, isCampaign) {
      commit('SET_IS_CAMPAIGN', isCampaign)
    },
    setOrder({ commit, dispatch }, order) {
      commit('SET_ORDER', order)
      dispatch('resetProjects')
    },
    setType({ commit, state, dispatch }, type) {
      commit('SET_TYPE', type)
      if(!state.projects[state.type].items.length)
        dispatch('getProjects')
    },
    setFetching({ commit }, fetching) {
      commit('SET_FETCHING', fetching)
    }
  },
  getter: {}
}
